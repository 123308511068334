<template>
  <div
    class="footer-main-wrapper"
    :class="{
      'merge-journey': isMergeJourney || isDldxPs,
    }"
  >
    <div class="footer">
      <div
        class="collapse-container"
        @click="toggle"
      >
        <div class="about-us">
          เกี่ยวกับเรา
        </div>
        <div class="icon-wrapper">
          <img
            v-if="!isMergeJourney || !isDldxPs"
            class="arrow"
            :class="{isActive: !isNotCollapse}"
            src="~@/assets/icons/accordion/icon-arrow-down.svg"
            alt="arrow-down-icon"
          >
          <img
            v-else
            class="arrow"
            :class="{isActive: !isNotCollapse}"
            alt="arrow-down-icon"
            src="~@/assets/images/merge-journey/chevronDown.png"
          >
        </div>
      </div>
      <Divider style-type="divider-light" />
      <FooterLinks :collapse="isNotCollapse" />
      <Divider
        v-show="!isMergeJourney || !isDldxPs"
        style-type="divider-light line"
        :class="{isActive: !isNotCollapse}"
      />
      <div class="footer-bottom">
        <div class="footer-box-1">
          <div class="content">
            พูดคุยกับเราได้ที่
          </div>
          <div class="call">
            <a href="tel:0-2740-7400">0-2740-7400</a>
          </div>
        </div>
        <div
          class="footer-box-2"
          v-lazy-container="{selector: 'img'}"
        >
          <span class="footer-box">
            <a
              class="social-icon"
              href="https://www.facebook.com/KrungsriAuto/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                v-if="!isMergeJourney || !isDldxPs"
                alt="กรุงศรี ออโต้ Facebook"
                :data-src="require('@/assets/images/icon-facebook-new.svg')"
              >
              <img
                v-else
                alt="กรุงศรี ออโต้ Facebook"
                :data-src="require('@/assets/images/merge-journey/facebook.png')"
              >
            </a>
            <a
              class="social-icon"
              href="https://line.me/R/ti/p/%40krungsriauto"
              target="_blank"
              rel="noreferrer"
            >
              <img
                v-if="!isMergeJourney || !isDldxPs"
                alt="กรุงศรี ออโต้ Line"
                :data-src="require('@/assets/images/icon-line.svg')"
              >
              <img
                v-else
                alt="กรุงศรี ออโต้ Facebook"
                :data-src="require('@/assets/images/merge-journey/LINE.png')"
              >
            </a>
            <a
              class="social-icon"
              href="https://www.youtube.com/user/KrungsriAutoTV"
              target="_blank"
              rel="noreferrer"
            >
              <img
                v-if="!isMergeJourney || !isDldxPs"
                alt="กรุงศรี ออโต้ Youtube"
                :data-src="require('@/assets/images/icon-youtube.svg')"
              >
              <img
                v-else
                alt="กรุงศรี ออโต้ Facebook"
                :data-src="require('@/assets/images/merge-journey/Youtube.png')"
              >
            </a>
            <a
              class="social-icon"
              href="https://www.tiktok.com/@krungsriauto"
              target="_blank"
              rel="noreferrer"
            >
              <img
                v-if="!isMergeJourney || !isDldxPs"
                alt="กรุงศรี ออโต้ Tiktok"
                :data-src="require('@/assets/images/icon-tiktok.svg')"
              >
              <img
                v-else
                alt="กรุงศรี ออโต้ Facebook"
                :data-src="require('@/assets/images/merge-journey/Tiktok.png')"
              >
            </a>
          </span>
        </div>
      </div>
      <div class="copyright">
        © สงวนลิขสิทธิ์ 2560 ธนาคารกรุงศรีอยุธยา จำกัด (มหาชน) - 1222 ถนนพระรามที่ 3 แขวงบางโพงพาง เขตยานนาวา กรุงเทพ 10120 และ บริษัท อยุธยา แคปปิตอล ออโต้ ลีส จำกัด (มหาชน) - 550
        อาคาร กรุงศรีเพลินจิต ทาวเวอร์ ชั้น 16 ถนนเพลินจิต แขวงลุมพินี เขตปทุมวัน กรุงเทพมหานคร 10330
      </div>
    </div>
  </div>
</template>

<script>
import Divider from '@/components/based/Divider'
import FooterLinks from '@/components/layout/FooterLinks'

export default {
  components: {
    Divider,
    FooterLinks,
  },
  props: {
    collapseActive: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    const d = new Date()
    const year = d.getFullYear() + 543
    return {
      isNotCollapse: !this.collapseActive,
      year,
    }
  },
  watch: {
    collapseActive (collapseActive) {
      this.isNotCollapse = !collapseActive
    },
  },
  computed: {
    isMergeJourney () {
      return (
        (window.features.MERGE_JOURNEY && this.$route.name === 'home') ||
        this.$route.name === 'ResultPromptstart' ||
        this.$route.path === '/preApproval' ||
        this.$route.path === '/crm/preapprove/result' ||
        this.$route.name === 'DigitalLendingLeadForm' ||
        this.$route.name === 'DigitalLendingLeadFormThankyou' ||
        this.$route.name === 'DigitalLendingResult'
      )
    },
    isDldxPs () {
      return (
        window.features.MERGE_JOURNEY &&
        (this.$route.name === 'ScreeningQuestionIntro' ||
          this.$route.name === 'PlanToBuy' ||
          this.$route.name === 'ReadyToBuy' ||
          this.$route.name === 'GetToKnowProduct' ||
          this.$route.name === 'DigitalLendingResult')
      )
    },
  },
  methods: {
    toggle () {
      this.isNotCollapse = !this.isNotCollapse
    },
  },
}
</script>

<style scoped lang="scss">
.footer-main-wrapper {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  flex: 1;
  position: relative;
  background-color: #fffdf2;
  padding: 0 20px;

  .divider-light {
    border-bottom: 1px solid hsla(0, 0%, 0%, 0.04);
    margin: 0 0 20px;

    &.line {
      display: none;
    }

    &.isActive {
      display: block;
    }
  }

  .collapse-container {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 36px;
    cursor: pointer;
    padding: 24px 0;

    .arrow {
      transition: transform 0.2s ease-out;

      &.isActive {
        transform: rotate(180deg);
      }
    }

    .about-us {
      font-size: 14px;
      font-weight: 500;
      line-height: 1.64;
      color: hsla(0, 0%, 0%, 0.8);
      padding-left: 8px;
    }

    .icon-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  & /deep/ footer {
    transition: max-height 0.4s ease;
    transition: opacity 0.4s ease;

    .footer-link-grid {
      padding-top: 0;
      padding-bottom: 0px;
    }
  }

  &.merge-journey {
    background-color: #fffdf2;
  }

  footer {
    background-color: #fffdf2;
  }
}

.footer {
  margin: 0 auto;
  max-width: 580px;

  @media (min-width: 1025px) {
    max-width: 960px;
  }
}

.footer-bottom {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 12px;
  padding-top: 20px;

  .footer-box-2 {
    .footer-box {
      display: flex;
      margin-top: 24px;
    }
  }

  .content {
    font-size: 16px;
    text-align: center;
    font-weight: 500;
    line-height: 1.63;
    color: hsl(4, 9%, 32%);
  }

  .call {
    font-size: 24px;
    text-align: center;
    font-weight: 500;
    line-height: 1.08;
    color: hsl(4, 9%, 32%);
    margin-top: 8px;

    a {
      color: #6f5f5e;
    }
  }
  .social-icon {
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 8px;

    img {
      width: 36px;
      height: 36px;
    }
  }
}

.copyright {
  font-size: 12px;
  width: 100%;
  padding: 24px 20px 24px;
  text-align: center;
}

.merge-journey {
  background-color: #fffdf2;
  * {
    background-color: #fffdf2;
    color: #292828;
    font-size: 14px;
  }
  .footer {
    padding: 24px;
    @media (max-width: 1024px) {
      max-width: unset;
    }
    .content {
      color: #6f5f5e;
      font-weight: 600;
    }
    .call a {
      color: #292828;
      font-size: 18px;
      font-weight: 600;
    }
  }
  .about-us {
    font-weight: 600 !important;
  }
  .icon-wrapper {
    img {
      width: 16px;
    }
  }
  /deep/ .footer-link-grid {
    gap: 0 8px;
    .footer-links-section a {
      color: #858282;
    }
    .footer-links-section div {
      padding: 4px 0;
    }
    .footer-links-section {
      margin-bottom: 16px;
    }
    .footer-links-section .bold {
      color: #6f5f5e;
      font-weight: 600;
      font-size: 14px;
    }
  }
  .footer-bottom {
    padding-top: 0;
    .footer-box-2 .footer-box {
      margin-top: 16px;
    }
  }
  .divider-light {
    border-color: #e0e0e0;
  }
  .copyright {
    padding: 0;
    padding-top: 16px;
    font-size: 10px;
    line-height: 16px;
    text-align: center;
    color: #858282;
    font-weight: 600;
  }
  .social-icon {
    margin: 0 12px;
    gap: 0 24px;
    width: unset;
    height: unset;
    img {
      height: 40px;
      width: 40px;
    }
  }
  /* @media (min-width: 768px) {
    .footer {
      max-width: unset;
    }
  } */
}
</style>
